// Generated by Framer (b0f2619)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {rbv7_fm8s: {hover: true}};

const cycleOrder = ["rbv7_fm8s"];

const serializationHash = "framer-9t6yy"

const variantClassNames = {rbv7_fm8s: "framer-v-1plwgx0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rbv7_fm8s", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1plwgx0", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"rbv7_fm8s"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"rbv7_fm8s-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-6kfm1o"} data-framer-name={"Button"} layoutDependency={layoutDependency} layoutId={"glyIYY1bv"} style={{backgroundColor: "var(--token-40b626ce-c569-43fa-bb88-44a803ea9f6e, rgb(59, 59, 59))", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40}} variants={{"rbv7_fm8s-hover": {backgroundColor: "var(--token-96ef5b7b-ed6e-4a26-9b93-c0662014d76c, rgb(0, 0, 0))"}}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-9t6yy[data-border=\"true\"]::after, .framer-9t6yy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9t6yy.framer-vo9mxp, .framer-9t6yy .framer-vo9mxp { display: block; }", ".framer-9t6yy.framer-1plwgx0 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px 8px 8px 0px; position: relative; width: min-content; }", ".framer-9t6yy .framer-6kfm1o { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); overflow: visible; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9t6yy.framer-1plwgx0 { gap: 0px; } .framer-9t6yy.framer-1plwgx0 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-9t6yy.framer-1plwgx0 > :first-child { margin-top: 0px; } .framer-9t6yy.framer-1plwgx0 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ElCbsIuT4":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerpDW6oHlTS: React.ComponentType<Props> = withCSS(Component, css, "framer-9t6yy") as typeof Component;
export default FramerpDW6oHlTS;

FramerpDW6oHlTS.displayName = "Button/Home";

FramerpDW6oHlTS.defaultProps = {height: 32, width: 24};

addFonts(FramerpDW6oHlTS, [])